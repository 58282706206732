import request, { ResponseError } from '@capturi/request'

import { PhoneFilterValues } from '@capturi/api-filters'
import { toFilterValues } from '@capturi/filters'
import {
  InfiniteData,
  UseInfiniteQueryResult,
  UseQueryResult,
  useInfiniteQuery,
  useQuery,
} from '@tanstack/react-query'

import {
  BaseConversation,
  ConversationsInclScoresResponseModel,
  ConversationsInclTrackerHitsResponseModel,
  ConversationsResponseModel,
} from '../models'

export const getNextPageParam = (lastPage: {
  continuationToken: string | null
}) => lastPage.continuationToken

export const selectConversations = (
  data: InfiniteData<ConversationsResponseModel>,
): InfiniteData<BaseConversation[]> => ({
  pages: data.pages.map((page) => page.conversations),
  pageParams: data.pageParams,
})

const selectConversationsWithTrackerHits = (
  data: InfiniteData<ConversationsInclTrackerHitsResponseModel>,
): InfiniteData<ConversationsInclTrackerHitsResponseModel> => ({
  pages: data.pages.map((page) => page),
  pageParams: data.pageParams,
})

export const useConversations = (
  filter: Record<string, unknown>,
  enabled = true,
): UseInfiniteQueryResult<InfiniteData<BaseConversation[], unknown>, Error> => {
  return useInfiniteQuery({
    enabled: enabled,
    queryKey: ['conversations', 'list', filter],
    queryFn: async ({ pageParam }) =>
      await request.post<ConversationsResponseModel>(
        'conversations/list?api-version=3.3&excludeDeleted=false',
        {
          json: pageParam
            ? { continuationToken: pageParam }
            : {
                ...filter,
                limit: 100,
                sortDirection: 1,
              },
        },
      ),
    getNextPageParam,
    initialPageParam: null,
    select: selectConversations,
  })
}

export const useConversationsIncludingTrackerHits = (
  filter: Record<string, unknown>,
): UseInfiniteQueryResult<
  InfiniteData<ConversationsInclTrackerHitsResponseModel, unknown>,
  ResponseError
> => {
  return useInfiniteQuery({
    queryKey: ['conversationsWithTrackerHits', filter],
    queryFn: async ({ pageParam }) =>
      await request.post<ConversationsInclTrackerHitsResponseModel>(
        'conversations/including-tracker-hits?api-version=3.3&excludeDeleted=false',
        {
          json: pageParam
            ? { continuationToken: pageParam }
            : { ...filter, limit: 30, sortDirection: 1 },
        },
      ),
    getNextPageParam,
    initialPageParam: null,
    select: selectConversationsWithTrackerHits,
  })
}

export const useConversationsIncludingScores = (
  filter: Record<string, unknown>,
): UseInfiniteQueryResult<
  InfiniteData<ConversationsInclScoresResponseModel>,
  ResponseError
> => {
  return useInfiniteQuery({
    queryKey: ['conversationsWithScores', filter],
    queryFn: async ({ pageParam }) =>
      await request.post<ConversationsInclScoresResponseModel>(
        'conversations/including-scores?api-version=3.3&excludeDeleted=false',
        {
          json: pageParam
            ? { continuationToken: pageParam }
            : { ...filter, limit: 30, sortDirection: 1 },
        },
      ),
    getNextPageParam,
    initialPageParam: null,
  })
}

const selectCasesCount = (data: { conversations: number }): number =>
  data.conversations
export function useConversationMatchCount(
  filterValues: PhoneFilterValues | undefined,
  period: { from: Date; to: Date },
): UseQueryResult<number, Error> {
  return useQuery({
    queryKey: ['conversationMatchCount', filterValues, period.from, period.to],
    queryFn: async () => {
      const response = await request.post<{ conversations: number }>(
        'conversations/count?api-version=3.3',
        {
          json: {
            ...toFilterValues(filterValues ?? {}),
            fromInclusive: period.from,
            toInclusive: period.to,
          },
        },
      )
      return response
    },
    select: selectCasesCount,
  })
}

export const useCallHistory = (
  customer: string,
): UseQueryResult<ConversationsResponseModel, ResponseError> =>
  useQuery({
    queryKey: ['callHistory', customer],
    queryFn: async () =>
      request.post<ConversationsResponseModel>(
        'conversations/list?api-version=3.3&excludeDeleted=false',
        {
          json: {
            limit: 50,
            customers: [customer],
          },
        },
      ),
  })
